<template>
  <div class="Awarp">
    <dashCard
        class="powerBox17"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox17_analyze','电率分析', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
              class="pick-type"
              v-for="(item, idx) in timetType"
              :key="idx"
              @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                item.name
              }}</font>
          </span>
        </div>
        <a-date-picker
            v-if="searchObj.TYPE == '按天'"
            :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-week-picker
            v-if="searchObj.TYPE == '按周'"
            :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-month-picker
            v-if="searchObj.TYPE == '按月'"
            :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-date-picker
            v-if="searchObj.TYPE == '按年'"
            :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
            mode="year"
            :open="panelOpen"
            @openChange="openChange"
            @panelChange="panelChange"
            format="YYYY"
        />
      </template>
      <div class="card-content1">
        <a-select
            v-model="searchObj['ITEM']"
            style="width: 220px"
            @change="handleChange"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
              v-for="(item, key) in dbData"
              :key="key"
              :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div
          class="card-content2"
          v-if="this.detailInfo.chart1.categoryData.length !== 0"
      >
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <powerBox17
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerBox17",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6};
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      gridLeft: [],
      gridrigth: [],
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },

        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "1AT1_进线柜",
          name: "1AT1_进线柜",
        },
        {
          code: "1AT2_进线柜",
          name: "1AT2_进线柜",
        },
        {
          code: "1AT3_进线柜",
          name: "1AT3_进线柜",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      chartOption1: {},
      detailInfo: {
        chart1: {
          categoryData: [],
        },
      },
      colorList: ["#F03040", "#51A34A", "#3366FF"],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    //dashboard数据字典
    dashboardOptions() {
      return this.$store.state.dashboardOptions;
    },
    assetDic() {
      return this.dashboardOptions.assetDic;
    },
    electricDic() {
      return this.dashboardOptions.electricDic;
    },
    dbData() {
      return this.$store.state.dbData;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");

      let flag = "";
      if (value.indexOf('-') == 0) {
        if (intPartFormat.indexOf('-') != 0) {
          flag = '-';
        }
      }

      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分

        return flag + intPartFormat + "." + floatPart;
      }
      return flag + intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData000() {
      this.detailInfo = {};
      var chart1 = {
        categoryData: ["8:00", "9:00", "10:00", "11:00", "12:00"],
        seriesData: [
          {
            name: "增",
            value: [66, 0, 91, 60, 60],
          },
          {
            name: "减",
            value: [0, -50, 0, 0, 0],
          },
          {
            name: "功率因数",
            value: [50, 45, 70, 70, 38],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },

    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },

    //每月报警预警数
    getData() {
      const url = Config.dev_url + "/api-apps-v2/api/v2/circuit/circuit_analysis";
      var data = {
        equipment: this.searchObj.ITEM,
        // days:"2022-03-02",
        // startTime:"",
        // endTime:""
      };
      Object.assign(data, this.transformSearch());
      // var data = {
      //     days: "2022-03-02",
      //     equipment: "1AT1_Trafo Incoming"
      //     }
      this.isLoading = true;
      this.$axios
          .post(url, data)
          .then((res) => {
            if (res.data.code == 0) {
              this.detailInfo = {};
              var chart1 = {
                categoryData: [],
                seriesData: [
                  {
                    name: this.LangHandler('increase','增数'),
                    value: [],
                  },
                  {
                    name: this.LangHandler('decrease','减数'),
                    value: [],
                  },
                  {
                    name: this.LangHandler('powerfactor','功率因数'),
                    value: [],
                  },
                ],
              };
              var result = res.data.data;
              var attrList = Object.keys(result);
              if (attrList.length > 0) {
                chart1.categoryData = result.categoryData;
                result.seriesData.forEach((item, index) => {
                  item.value.forEach((itemValue, itemValueIndex) => {
                    item.value[itemValueIndex] = this.toThousandsSeparator(itemValue);
                  })
                  switch (item.name) {
                    case "增数":

                      chart1.seriesData[0].value = item.value;
                      break;
                    case "减数":
                      chart1.seriesData[1].value = item.value;
                      break;
                    case "功率因数":
                      chart1.seriesData[2].value = item.value;
                      break;
                  }
                });
              }
              let newarr = [];
              this.detailInfo.chart1 = chart1;
              this.detailInfo.chart1.seriesData.forEach((item) => {
                item.value.forEach((val) => {
                  newarr.push(val);
                });
              });
              console.log(this.getNull(newarr));
              if (this.getNull(newarr) == newarr.length) {
                this.gridLeft = [0, 0, 0, 60];
                this.gridrigth = [0, 30, 0, 0];
              } else {
                this.gridLeft = [0, 0, 0, 20];
                this.gridrigth = [0, 0, 0, 0];
              }
              let lang = sessionStorage.getItem("locale") || 'CH'
              if(lang=='EN'){
                  this.gridLeft = [0, 0, 0, 190];
                }
              this.initEchart();
            } else {
              // this.$message.warning(res.data.msg)
            }
          })
          .catch((wrong) => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
            currentValue === null ||
            JSON.parse(currentValue) === null ||
            typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    getData0() {
      const url = Config.dev_url + "/api-apps/Circuit/CircuitAnalysis";
      var data = {
        equipment: "1AT1_Trafo Incoming",
        startTime: "2022-03-02 00:00:00",
        endTime: "2022-03-09 00:00:00",
      };
      this.$axios
          .post(url, data)
          .then((res) => {
            if (res.data.code == 0) {
              console.log(
                  res.data.data,
                  "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              );
            } else {
              // this.$message.warning(res.data.msg)
            }
          })
          .catch((wrong) => {
          });
    },
    initEchart() {
      var dom = this.$refs["chart1"];
      setTimeout(() => {
        if (!!dom) {
          dom.handleWindowResize();
        }
      }, 30);
      var {categoryData, seriesData} = this.detailInfo.chart1;
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "2%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            // console.log(params);
            let str = "";
            str += params[0].name + "<br>";
            if (
                (params[0].value == undefined || params[0].value == "null") &&
                (params[1].value == undefined || params[1].value == "null") &&
                (params[2].value == undefined || params[2].value == "null")
            ) {
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[0].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[0].seriesName} : ${params[0].value}</span></div>`;
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[1].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[1].seriesName} : ${params[1].value}</span></div>`;
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[2].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[2].seriesName} : ${params[2].value}</span></div>`;
            } else if (
                params[0].value != undefined &&
                params[0].value != "null" &&
                params[0].value != ""
            ) {
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[0].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[0].seriesName} : ${params[0].value}</span></div>`;
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[2].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[2].seriesName} : ${params[2].value}</span></div>`;
            } else if (
                params[1].value != undefined &&
                params[1].value != "null" &&
                params[1].value != ""
            ) {
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[1].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[1].seriesName} : ${params[1].value}</span></div>`;
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${params[2].color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${params[2].seriesName} : ${params[2].value}</span></div>`;
            }

            // params.forEach((item) => {
            //   // console.log(item.value);
            //   if (item.value != undefined && item.value != "undefined") {
            //     str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${item.color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${item.value}</span></div>`;
            //   }else{
            //     str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${item.color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${item.value}</span></div>`;
            //   }
            // });
            return str;
          },
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: categoryData,
          // boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: [
          {
            name: this.commonLangHandler('powerBox17_ratio','增减比', this.getZEdata) + '（%）',
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },
          {
            name: this.LangHandler('powerfactor','功率因数'),
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridrigth,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: false,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
            max: 1,
          },
        ],

        // 控住柱状图样式
        // series:series,
        series: [
          {
            type: "bar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            name: seriesData[0].name,
            data: seriesData[0].value,
            smooth: false,
            showSymbol: false,
            barWidth: "10",
            stack: "dd",
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              // color: 'rgb(52, 102, 254)',
              width: 2,
            },
          },
          {
            type: "bar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            name: seriesData[1].name,
            data: seriesData[1].value,
            // yAxisIndex: 1,
            smooth: false,
            showSymbol: false,
            barWidth: "10",
            stack: "dd",
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              // color: 'rgb(23, 167, 53)',
              width: 2,
            },
          },
          {
            type: "line",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            name: seriesData[2].name,
            data: seriesData[2].value,
            yAxisIndex: 1,
            smooth: false,
            emphasis: {
              focus: "series",
            },

            lineStyle: {
              // color: 'rgb(104, 104, 104)',
              width: 2,
            },
          },
        ],
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      if (this.dbData.length > 0) {
        this.searchObj.ITEM = this.dbData[0].code;
        console.log(this.searchObj.ITEM);
      }
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.powerBox17 {
  .card-content1 {
  }

  .card-content2 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
